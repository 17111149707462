<script lang="ts">
	import eyeOffImage from '$lib/images/eye-off.svg';
	import eyeImage from '$lib/images/eye.svg';
	import Input from '$lib/shared/Input.svelte';

	export let value = '';

	let show = false;

	$: icon = show ? eyeImage : eyeOffImage;
	$: type = show ? 'text' : 'password';

	function toggleShowVisibility() {
		show = !show;
	}
</script>

<div class="relative">
	<Input bind:value {type} {...$$restProps} on:input on:blur on:focus extraClasses="!py-4" />
	<img
		class="absolute right-8 top-1/2 h-10 w-10 -translate-y-1/2 cursor-pointer object-contain active:scale-95"
		src={icon}
		alt="eye"
		on:click|capture={toggleShowVisibility} />
</div>
